import React from 'react';

function YouKnow({ src, title, description }) {
  return (
    <div className="relative pt-[305px] lg:pt-[358px] 2xl:pt-[50%] overflow-hidden rounded-lg">
      <img
        className="absolute left-0 top-0 object-cover h-full w-full"
        src={src}
        alt=""
      />
      <div className="absolute bottom-0 p-5">
        <div className="text-[#F7F7F7] text-2xl font-bold mb-2">{title}</div>
        <p className="text-[#F7F7F7] text-lg">{description}</p>
      </div>
    </div>
  );
}
export default YouKnow;
