import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AiIcon, SearchIcon } from '../../../assets/svg';
import { ROUTES } from '../../../common/constants';
import TrendingSearchesTags from './TrendingSearcheTags';
import TrendingSearches from './TrendingSearches';

function GlobalSearch({ dataList }) {
  const [isDivVisible, setIsDivVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState();
  const history = useHistory();
  const [showTitle, setShowTitle] = useState(true);

  const handleInputFocus = () => {
    setIsDivVisible(true);
  };

  const handleInputBlur = () => {
    setIsDivVisible(false);
  };

  const [questionList] = useState([
    'How to reduce vehicle emissions?',
    'What are the effects of vehicle emissions on air quality?',
    'What are the regulations for vehicle emissions?',
    'How do vehicle emissions contribute to climate change?',
    'What is the average emission of a car?',
    'How are vehicle emissions measured?',
    'What are low-emission vehicles?',
    'What is a vehicle emissions test?',
    'How to pass an emissions test?',
    'What are vehicle emission standards?',

    'What are the types of renewable energy?',
    'How does renewable energy work?',
    'What are the costs of renewable energy?',
    'How to invest in renewable energy?',
    'What is the future of renewable energy?',
    'How efficient is renewable energy?',
    'What are renewable energy tax credits?',
    'How to switch to renewable energy at home?',
    'What is the impact of renewable energy on jobs?',
    'What are renewable energy certificates?',

    'How to live sustainably on a budget?',
    'What are sustainable living practices?',
    'How does sustainable living impact the environment?',
    'What are sustainable building materials?',
    'What is a sustainable diet?',
    'How to reduce waste for sustainable living?',
    'What are the best sustainable living blogs?',
    'How to start a sustainable garden?',
    'What is sustainable fashion?',
    'How to make a sustainable living plan?',

    'What causes climate change?',
    'How to mitigate climate change?',
    'What are the effects of climate change?',
    'What is the Paris Agreement?',
    'What is climate change adaptation?',
    'How is climate change measured?',
    'What are climate change indicators?',
    'What is the impact of climate change on health?',
    'How does climate change affect animals?',
    'What are climate change solutions?',

    'How to improve home energy efficiency?',
    'What are energy-efficient appliances?',
    'What is an energy efficiency rating?',
    'How does energy efficiency reduce costs?',
    'What are the benefits of energy efficiency?',
    'What are energy efficiency grants?',
    'How to conduct an energy efficiency audit?',
    'What is the role of LED lighting in energy efficiency?',
    'How does insulation improve energy efficiency?',
    'What is the Energy Star rating?',

    'What are the main sources of greenhouse gases?',
    'How to reduce greenhouse gas emissions?',
    'What is the greenhouse effect?',
    'How do greenhouse gases affect climate?',
    'What are greenhouse gas offsets?',
    'What are the levels of greenhouse gases?',
    'How are greenhouse gases measured?',
    'What is carbon capture for greenhouse gases?',
    'What is the role of agriculture in greenhouse gas emissions?',
    'What are short-lived climate pollutants?',

    'What materials are recyclable?',
    'How does recycling work?',
    'What are the benefits of recycling?',
    'How to recycle electronics?',
    'What is the impact of recycling on the environment?',
    'What is single-stream recycling?',
    'How to recycle plastic?',
    'What are recycling codes?',
    'How to recycle batteries?',
    'What is composting in recycling?',

    'How do solar panels work?',
    'What is the cost of installing solar panels?',
    'How efficient are solar panels?',
    'What are the benefits of solar energy?',
    'How to maintain solar panels?',
    'What are solar panel grants?',
    'What is the lifespan of solar panels?',
    'How to clean solar panels?',
    'What are thin-film solar panels?',
    'How to calculate solar panel ROI?',

    'What is the range of an electric vehicle?',
    'How to charge an electric vehicle?',
    'What are the costs of owning an electric vehicle?',
    'What are the best electric vehicles?',
    'How do electric vehicles impact the environment?',
    'What are electric vehicle tax credits?',
    'How do electric vehicle batteries work?',
    'What is regenerative braking in electric vehicles?',
    'What are the charging standards for electric vehicles?',
    'How to extend the lifespan of an electric vehicle battery?',

    'What are the types of EV chargers?',
    'How long does it take to charge an electric vehicle?',
    'How to install an EV charger at home?',
    'What is the cost of an EV charger?',
    'Are public EV chargers free?',
    'What is fast charging in EV?',
    'How to find an EV charging station?',
    'What are the safety features of an EV charger?',
    'What is the efficiency of EV chargers?',
    'How to choose an EV charger?',

    'What are the types of insulation?',
    'How to install home insulation?',
    'What is the R-value in insulation?',
    'How does insulation save energy?',
    'What is the cost of home insulation?',
    'What is blown-in insulation?',
    'How to choose the right insulation material?',
    'What is the lifespan of insulation?',
    'How to remove old insulation?',
    'What are the health risks of insulation?',

    'What are the types of boilers?',
    'How to maintain a boiler?',
    'What is the efficiency of a boiler?',
    'How to choose a boiler for home?',
    'What is the lifespan of a boiler?',
    'What is a combi boiler?',
    'How to troubleshoot a boiler?',
    'What are the safety features of a boiler?',
    'What is the cost of installing a boiler?',
    'How to improve boiler efficiency?',

    'What is an EPC rating?',
    'How to improve EPC rating?',
    'What is included in an EPC?',
    'How to get an EPC?',
    'What is the cost of an EPC?',
    'How long is an EPC valid for?',
    'What is the impact of EPC on property value?',
    'What are the penalties for not having an EPC?',
    'How to read an EPC?',
    'What is a commercial EPC?',

    'How to calculate carbon footprint?',
    'What are the main contributors to carbon footprint?',
    'How to reduce carbon footprint?',
    'What is the average carbon footprint per person?',
    'What are carbon offset programs?',
    'What is the carbon footprint of flying?',
    'How does diet affect carbon footprint?',
    'What is the carbon footprint of electric cars?',
    "How to measure a company's carbon footprint?",
    'What is the carbon footprint of meat production?',
  ]);

  // commenting for future use
  // const handleSearch = (text) => {
  //   fetchBlogs(text, 0);
  //   setSearch(text);
  // };
  // const debouncedSave = debounce(handleSearch, 500);

  const handleSubmit = (event) => {
    event?.preventDefault();
    if (search?.trim()) {
      history?.push(`${ROUTES?.SEARCH_AI}/${search}`);
    }
  };
  return (
    <div
      className={`relative  bg-white ${
        isDivVisible ? 'rounded-t-2xl shadow-searchShadow' : 'rounded-2xl'
      } `}
      onMouseLeave={handleInputBlur}
    >
      <div className="h-[58px] w-full  overflow-hidden flex items-center p-4 gap-4 ">
        <SearchIcon />
        <form name="search-form" onSubmit={handleSubmit} className="w-full">
          <input
            className={`md:w-[calc(100%-180px)] w-[calc(100%-150px)] placeholder:text-[#00473480] appearance-none outline-none text-primary text-lg font-medium `}
            type="text"
            placeholder="Search for sustainability..."
            onClick={handleInputFocus}
            // onChange={(e) => debouncedSave(e?.target?.value)}
            onInput={handleInputFocus}
            onChange={(e) => setSearch(e?.target?.value)}
          />
        </form>
        <div className="rounded-2xl absolute right-0 top-0 h-full bg-ai-gradient max-w-[150px] md:max-w-[180px] w-full flex items-center gap-2 justify-center text-primary text-base font-medium">
          <AiIcon />
          AI Powered
        </div>
      </div>
      {isDivVisible && (
        <div
          className="absolute top-[58px] p-4 w-full border border-[#D5D7D7] bg-white rounded-b-2xl z-[1]"
          onClick={handleInputFocus}
        >
          {showTitle && (
            <TrendingSearchesTags dataList={dataList} loading={false} />
          )}
          <div className="max-h-62 overflow-auto custom-scrollbar">
            <TrendingSearches
              questionList={questionList}
              search={search}
              handleTitle={(toggle) => setShowTitle(toggle)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default GlobalSearch;
