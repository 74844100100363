import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import { ROUTES } from '../common/constants';
import AllComponents from '../modules/AllComponents';
import GreenHub from '../modules/GreenHub';
import SearchAi from '../modules/SearchAi';

const ContentRoutes = () => (
  <Switch>
    <Route path={`${ROUTES?.SEARCH_AI}/:question`} exact component={SearchAi} />
    <Route path={ROUTES?.ALLCOMPONENT} exact component={AllComponents} />
    <Route path={ROUTES?.MAIN} exact component={GreenHub} />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default ContentRoutes;
