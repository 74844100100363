import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import ContentRoutes from './ContentRoutes';
import AppFooter from './components/footer/AppFooter';
import AppHeader from './components/header/AppHeader';

const App = () => (
  <div className="font-display text-descriptionBlack text-sm">
    <AppHeader />
    <ContentRoutes />
    <AppFooter />
  </div>
);

export default App;
