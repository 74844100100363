import React from 'react';
import {
  AppStore,
  Facebook,
  Instagram,
  Linkedin,
  Logo,
  PlayStore,
  Twitter,
} from '../../../assets/svg';

function AppFooter() {
  return (
    <footer>
      <div className="py-[60px] md:py-[100px] lg:py-[140px]">
        <div className="container mx-auto px-4 ">
          <div className="max-w-[1060px] mx-auto flex justify-between gap-5 flex-col md:flex-row">
            <div className="max-w-[404px]">
              <a href="/">
                <Logo />
              </a>
              <p className="mt-5 max-w-[253px] w-full">
                We're building the UK's greener, digital bank, providing
                hardworking people across the UK with sustainable ways to save,
                borrow, spend and share.
              </p>
              <ul className="flex gap-[10px] mt-[35px]">
                <li>
                  <a href="/">
                    <Twitter />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <Facebook />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <Linkedin />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <Instagram />
                  </a>
                </li>
              </ul>
              <div className="h-[1px] w-full bg-footer-divider my-5 md:mt-[145px] md:mb-10" />
              <div className="flex gap-3 md:gap-5 store">
                <a
                  target="_blank"
                  href="https://apps.apple.com/gb/app/tandem-bank/id1128467665"
                  rel="noreferrer"
                >
                  <AppStore />
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=uk.co.tandem.android.app&hl=en_GB"
                  rel="noreferrer"
                >
                  <PlayStore />
                </a>
              </div>
            </div>
            <div className="flex-1 max-w-[656px]">
              <div className="grid sm:grid-cols-2 lg:grid-cols-3">
                <div className="flex flex-col gap-4 mb-12">
                  <div className="text-primary text-xs tracking-[2.6px] font-medium uppercase">
                    SAVE
                  </div>
                  <ul className="flex flex-col gap-4">
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Instant Access Saver
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Fixed Term Saver
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Savings Accounts
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col gap-4 mb-12">
                  <div className="text-primary text-xs tracking-[2.6px] font-medium uppercase">
                    Borrow
                  </div>
                  <ul className="flex flex-col gap-4">
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Greener Motor Finance
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Home Loans
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Home Improvement Loans
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Mortgages
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col gap-4 mb-12">
                  <div className="text-primary text-xs tracking-[2.6px] font-medium uppercase">
                    Support
                  </div>
                  <ul className="flex flex-col gap-4">
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Support
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Financial Difficulties
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Complaints Policy
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Modern Slavery Act
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col gap-4 mb-12">
                  <div className="text-primary text-xs tracking-[2.6px] font-medium uppercase">
                    Share
                  </div>
                  <ul className="flex flex-col gap-4">
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Loop Money
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col gap-4 mb-12">
                  <div className="text-primary text-xs tracking-[2.6px] font-medium uppercase">
                    Company
                  </div>
                  <ul className="flex flex-col gap-4">
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Newsroom
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Careers
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col gap-4 mb-12">
                  <div className="text-primary text-xs tracking-[2.6px] font-medium uppercase">
                    Green Hub
                  </div>
                  <ul className="flex flex-col gap-4">
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Our Blog
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-[#2A2C2C] hover:text-primary leading-5 duration-300 ease-in"
                        href="/"
                      >
                        Marketplace
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-6 md:py-8 border-t  border-[#C6C8C8]">
        <div className="container mx-auto px-4">
          <div className="max-w-[1060px] mx-auto ">
            <div className="flex flex-col md:flex-row">
              <div className="text-[#464949] max-w-[257px] w-full mb-5 md:mb-0">
                2023 © Tandem Bank
              </div>
              <ul className="flex gap-10 ">
                <li>
                  <a href="/" className="hover:text-primary">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="/" className="hover:text-primary">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="py-7 md:py-10  border-t border-b border-[#C6C8C8]">
        <div className="container mx-auto px-4">
          <div className="max-w-[1060px] mx-auto flex justify-between">
            <p className="text-[#464949] leading-[23px]">
              Tandem Bank Limited t/a Tandem is authorised by the Prudential
              Regulation Authority and regulated by the Financial Conduct
              Authority and the Prudential Regulation Authority. Tandem Bank
              Limited is registered on the Financial Services Register (FRN:
              204479). Registered in England and Wales under company number
              00955491 with registered office address Viscount Court, Sir Frank
              Whittle Way, Blackpool, United Kingdom, FY4 2FB.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default AppFooter;
