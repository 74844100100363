import React from 'react';
import GridImg from '../../assets/images/Grid-Pattern.png';
import GlobalSearch from './globalsearch/GlobalSearch';

function HomeSearchSection({
  title,
  description,
  dataList,
  fetchBlogs,
  loading,
}) {
  return (
    <div className="py-16 md:py-20 bg-hero-gradient relative">
      <img
        className="absolute left-0 top-0 h-full w-full object-cover object-center pointer-events-none isolate z-0"
        src={GridImg}
        alt=""
      />
      <div className="container px-4 mx-auto">
        <h1 className="text-[#F7F7F7] text-[40px] md:text-[45px] lg:text-[56px] leading-tight lg:leading-[68px] font-semibold mb-4">
          {title}
        </h1>
        <p className="mb-4 text-[#F7F7F7] text-lg md:text-2xl leading-tight md:leading-9 mb-8">
          {description}
        </p>
        <GlobalSearch
          dataList={dataList}
          fetchBlogs={fetchBlogs}
          loading={loading}
        />
      </div>
    </div>
  );
}
export default HomeSearchSection;
