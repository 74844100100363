import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { SearchBlack, Trending } from '../../../assets/svg';

import { ROUTES } from '../../../common/constants';
import HighlightText from '../../../modules/HighlightText';

function TrendingSearches({ questionList, search, handleTitle }) {
  const [showTitle, setShowTitle] = useState(true);

  const List = ({ question, icon }) => (
    <li className="py-2">
      <Link
        className="text-base font-medium text-descriptionBlack flex  hover:text-primary duration-300 ease-in"
        to={`${ROUTES?.SEARCH_AI}/${question}`}
      >
        {icon}
        <div className="ml-3">
          <HighlightText text={question} searchTerm={search} />
        </div>
      </Link>
    </li>
  );
  const validateFilter = () => {
    if (
      questionList?.filter((question) =>
        question?.toLowerCase()?.includes(search?.toLowerCase()),
      )?.length > 0
    ) {
      return questionList?.filter((question) =>
        question?.toLowerCase()?.includes(search?.toLowerCase()),
      );
    }
    return false;
  };

  const renderListings = useMemo(() => {
    if (search) {
      if (validateFilter()) {
        setShowTitle(false);
        handleTitle(false);
        return validateFilter()
          ?.map((question) => (
            // eslint-disable-next-line react/jsx-key
            <List question={question} icon={<SearchBlack />} />
          ))
          ?.slice(0, 5);
      }
      setShowTitle(true);
      handleTitle(true);
      return questionList
        ?.map((question) => (
          // eslint-disable-next-line react/jsx-key
          <List question={question} icon={<Trending />} />
        ))
        ?.slice(0, 5);
    }
    setShowTitle(true);
    handleTitle(true);
    return questionList
      ?.map((question) => (
        // eslint-disable-next-line react/jsx-key
        <List question={question} icon={<Trending />} />
      ))
      ?.slice(0, 5);
  }, [search, questionList]);

  return (
    <div>
      <div className="text-base text-[#888] leading-6 mb-1">
        {showTitle && 'Trending Searches'}
      </div>
      <ul>{renderListings}</ul>
    </div>
  );
}
export default TrendingSearches;
