import React, { useState } from 'react';
import BlogImg from '../assets/images/blog-img.png';
import BlogFilterTag from '../components/modulecomponents/BlogFilter';
import Card from '../components/modulecomponents/Card';

export default function AllComponents() {
  // eslint-disable-next-line no-unused-vars
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = ['Category 1', 'Category 2', 'Category 3'];

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    // Handle filtering logic here based on the selected category.
  };
  return (
    <div className="wrapper py-6">
      <div className="container m-auto px-4">
        <div className="flex gap-4 flex-col lg:flex-row">
          <div className="max-w-[296px]">
            <Card
              src={BlogImg}
              title="Sustainable Travel: Top UK Holiday Destinations by Train"
              description="Travelling by train is the most eco-friendly way to see the UK. Discover our top destinations for sustainable travel this summer."
            />
          </div>
          <div className="max-w-[442px]">
            <Card
              marketing
              horizontal
              src={BlogImg}
              title="Sustainable Travel: Top UK Holiday Destinations by Train"
              description="Travelling by train is the most eco-friendly way to see the UK. Discover our top destinations for sustainable travel this summer."
            />
          </div>
        </div>
        <div>
          <BlogFilterTag
            categories={categories}
            onSelectCategory={handleCategorySelect}
          />
        </div>
      </div>
    </div>
  );
}
