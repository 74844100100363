import * as Sentry from '@sentry/react';
import React, { useContext, useEffect, useState } from 'react';
import { Router, Switch } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PublicRoute from './PublicRoute';
import App from './app/App';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import history from './historyData';

const MyFallbackComponent = ({ error, componentStack }) => (
  <div status="error">
    <h1>{ERROR_PAGE_TITLE}</h1>
    <p>{ERROR_PAGE_SUBTITLE}</p>
    <div className="desc">
      <p>
        <h4> Error:</h4>
      </p>
      <p>{error?.message?.toString()}</p>
      <p>
        <h4> Stacktrace:</h4>
      </p>
      <p>{componentStack}</p>
    </div>
  </div>
);

const Routes = () => {
  const { getToken } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const path = history?.location?.pathname;
  const idToken = getToken();

  useEffect(() => {
    if (path === ROUTES?.LOGOUT || idToken) {
      // commenting for future use
      // getCurrentUser();
    } else {
      setLoading(false);
    }

    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router history={history}>
        {maintenance === 'true' ? (
          <MaintenancePage />
        ) : (
          <Switch>
            <PublicRoute exact path={ROUTES?.LOGIN} component={Error404} />
            <PublicRoute path={ROUTES?.MAIN} component={App} />
          </Switch>
        )}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default Routes;
