import React from 'react';
import Slider from 'react-slick';
import YouKnowImg from '../../../assets/images/didyouknow.jpeg';
import YouKnow from './YouKnow';

function YouKnowList() {
  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };
  return (
    <div className="w-full overflow-hidden">
      <Slider {...settings}>
        <YouKnow
          src={YouKnowImg}
          title="Did you know?"
          description="70% of UK adults recognise that climate change is an urgent issue and
          63% aren’t prepared to spend their own money to tackle it."
        />
        <YouKnow
          src={YouKnowImg}
          title="Did you know?"
          description="70% of UK adults recognise that climate change is an urgent issue and
          63% aren’t prepared to spend their own money to tackle it."
        />
        <YouKnow
          src={YouKnowImg}
          title="Did you know?"
          description="70% of UK adults recognise that climate change is an urgent issue and
          63% aren’t prepared to spend their own money to tackle it."
        />
      </Slider>
    </div>
  );
}
export default YouKnowList;
