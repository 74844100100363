import React from 'react';
import { loop } from '../../common/utils';
import Card from './Card';

function HomeBlogList(props) {
  const { dataList, loading } = props;
  return (
    <div className="block pt-8 pb-[100px]">
      <div className="container px-4 mx-auto">
        <h2 className="text-2xl md:text-[28px] font-semibold leading-9 mb-6">
          Blogs for Sustainability
        </h2>
        <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8">
          {loading
            ? loop(10)?.map((key) => <Card.Loading key={key} />)
            : dataList?.map((data) => (
                <Card
                  src={data?.img_src}
                  href={data?.Url}
                  key={data?.id}
                  description={data?.['Reference Text']}
                  title={data?.title}
                />
              ))}
        </div>
      </div>
    </div>
  );
}
export default HomeBlogList;
