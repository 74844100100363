import React, { useEffect, useState } from 'react';
import Accordion from '../components/common/Accordion';
import HomeBlogList from '../components/modulecomponents/HomeBlogList';
import HomeSearchSection from '../components/modulecomponents/HomeSearchSection';
import FindSection from '../components/modulecomponents/findsection/FindSection';
import YouKnowList from '../components/modulecomponents/youknowsection/YouKnowList';

import card1 from '../assets/blogs/card-1.png';
import card2 from '../assets/blogs/card-2.png';
import card3 from '../assets/blogs/card-3.png';
import card4 from '../assets/blogs/card-4.png';
import card5 from '../assets/blogs/card-5.png';
import card6 from '../assets/blogs/card-6.png';
import card7 from '../assets/blogs/card-7.png';
import card8 from '../assets/blogs/card-8.png';
import card9 from '../assets/blogs/card-9.png';

function GreenHub() {
  const [dataList] = useState([]);
  const [loading, setLoading] = useState(false);

  const dummyList = [
    {
      Id: 1,
      'Reference Text':
        'Travelling by train is the most eco-friendly way to see the UK. Discover our top destinations for sustainable travel this summer.',
      Url: 'https://www.tandem.co.uk/complaints',
      Page: 'Complaints',
      category: 'complaints',
      img_src: card1,
      title: 'Sustainable Travel: Top UK Holiday Destinations by Train',
    },
    {
      Id: 2,
      'Reference Text':
        'There are many ways to support sustainable fashion, from buying from ethical brands to thrifting and upcycling.',
      Url: 'https://www.tandem.co.uk/complaints',
      Page: 'Complaints',
      category: 'complaints',
      img_src: card2,
      title:
        'Fashion with a Purpose: Embracing Sustainability over Seasonality ',
    },
    {
      Id: 3,
      'Reference Text':
        'World EV Day is a global celebration of the switch to EV transport and Tandem is joining the call for positive change.',
      Url: 'https://www.tandem.co.uk/complaints',
      Page: 'Complaints',
      category: 'complaints',
      img_src: card3,
      title: 'World EV Day – September 9th',
    },
    {
      Id: 4,
      'Reference Text':
        "Forget about expensive renovations; here are five quick and cost-effective ways to enhance your home's energy efficiency.",
      Url: 'https://www.tandem.co.uk/complaints',
      Page: 'Complaints',
      category: 'complaints',
      img_src: card4,
      title: 'Five quick ways to make your home more energy efficient ',
    },
    {
      Id: 5,
      'Reference Text':
        '63% of people aren’t prepared to spend money to tackle climate change. Why Intention Alone Can’t Solve Climate Change',
      Url: 'https://www.tandem.co.uk/complaints',
      Page: 'Complaints',
      category: 'complaints',
      img_src: card5,
      title: 'The Green Gap: Do you fall into it?',
    },
    {
      Id: 6,
      'Reference Text':
        'World EV Day is a global celebration of the switch to EV transport and Tandem is joining the call for positive change.',
      Url: 'https://www.tandem.co.uk/complaints',
      Page: 'Complaints',
      category: 'complaints',
      img_src: card6,
      title: 'World EV Day – September 9th',
    },
    {
      Id: 7,
      'Reference Text':
        'There are many ways to support sustainable fashion, from buying from ethical brands to thrifting and upcycling.',
      Url: 'https://www.tandem.co.uk/complaints',
      Page: 'Complaints',
      category: 'complaints',
      img_src: card7,
      title:
        'Fashion with a Purpose: Embracing Sustainability over Seasonality',
    },
    {
      Id: 8,
      'Reference Text':
        "Forget about expensive renovations; here are five quick and cost-effective ways to enhance your home's energy efficiency.",
      Url: 'https://www.tandem.co.uk/complaints',
      Page: 'Complaints',
      category: 'complaints',
      img_src: card8,
      title: 'Five quick ways to make your home more energy efficient ',
    },
    {
      Id: 9,
      'Reference Text':
        'There are many ways to support sustainable fashion, from buying from ethical brands to thrifting and upcycling.',
      Url: 'https://www.tandem.co.uk/complaints',
      Page: 'Complaints',
      category: 'complaints',
      img_src: card9,
      title:
        'Fashion with a Purpose: Embracing Sustainability over Seasonality',
    },
  ];
  const fetchBlogs = () => {
    setLoading(true);
    // commenting for future use
    // api
    //   ?.post(
    //     `/search?query=${query}&lambda_parameter=${lambdaParameter}&token=${process.env.REACT_APP_SERVER_TOKEN}`,
    //   )
    //   .then((response) => {
    //     const { data } = response;
    //     setDataList(data);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     return error;
    //   });
  };

  useEffect(() => {
    fetchBlogs('', 0);
  }, []);

  return (
    <div className="wrapper">
      <HomeSearchSection
        title="Ask, Learn, Go Greener"
        description="Let's make sustainability simple, one question at a time. Find tips, advice, and solutions with our AI-powered knowledge hub."
        dataList={dataList}
        fetchBlogs={fetchBlogs}
        loading={loading}
      />
      <div className="py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row gap-8">
            <div className="flex-1 w-full lg:w-1/2">
              <div className="border border-gray rounded-lg p-5">
                <h3 className="text-2xl font-semibold pb-4">
                  Frequently Asked Questions
                </h3>
                <Accordion
                  title="Are there any government regulations or certifications related to sustainable banking in the UK?"
                  content="Content for section 1"
                />
                <Accordion
                  title="How can I track the environmental and social impact of my sustainable banking choices?"
                  content="Content for section 2"
                />
                <Accordion
                  title="How can I reduce my carbon footprint through my banking activities?"
                  content="Content for section 2"
                />
              </div>
            </div>
            <div className="flex-1 w-full lg:w-1/2">
              <YouKnowList />
            </div>
          </div>
        </div>
      </div>
      <HomeBlogList dataList={dummyList} loading={false} />
      <FindSection
        title="Didn't find what you were looking for? "
        description="Head to our FAQs page to find answers to our most common
                questions, or contact our helpful support teams."
      />
    </div>
  );
}
export default GreenHub;
