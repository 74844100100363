import React from 'react';
import Button from '../../common/Button';

function FindSection({ title, description }) {
  return (
    <section className="py-10 lg:py-[60px] border-t border-b border-[#C6C8C8]">
      <div className="container mx-auto px-4">
        <div className="flex items-center flex-col lg:flex-row max-w-[1060px] mx-auto">
          <div className="flex-1 w-full md:w-1/2">
            <div className="max-w-[452px]">
              <h5 className="text-[22px] text-titleBlack font-semibold mb-4 text-center lg:text-left">
                {title}
              </h5>
              <p className="text-base text-[#464949] text-center lg:text-left">
                {description}
              </p>
            </div>
          </div>
          <div className="flex-1 w-full md:w-1/2 mt-5 lg:mt-0">
            <div className="flex gap-5 justify-end flex-col lg:flex-row ">
              <Button
                buttonType="button"
                text="Frequently Asked Questions"
                buttonStyle="primary"
              />
              <Button
                outline
                buttonType="button"
                text="Contact us"
                buttonStyle="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default FindSection;
