import React from 'react';
import { ButtonArrow } from '../../assets/svg';
import { DEFAULT_IMAGES } from '../../common/constants';
import Button from '../common/Button';

function Card({
  src,
  title,
  description,
  horizontal = false,
  marketing,
  onClick,
  href,
  target = '_blank',
  label,
  randomInt,
}) {
  const ElementType = marketing ? 'div' : 'a';

  // Define the props to be passed to the element
  const elementProps = marketing ? {} : { href, target };
  return (
    <ElementType
      {...elementProps}
      className={`flex  gap-4 ${
        horizontal ? 'flex-row min-h-[140px]' : 'flex-col'
      } ${!marketing && 'cursor-pointer'}`}
    >
      <div
        className={`bg-gray  rounded-[4px] overflow-hidden relative ${
          horizontal
            ? 'max-w-[170px] w-[50%] flex-shrink-0 pt-[30.32%]'
            : 'w-full pt-[77.703%]'
        }`}
      >
        <img
          src={src?.length === 0 ? DEFAULT_IMAGES?.[randomInt - 1] : src}
          className="absolute left-0 top-0 h-full w-full object-cover"
          alt={title || 'tandem'}
        />
      </div>
      <div className="inline-flex flex-col gap-2">
        <lable className="text-primary bg-primarylight px-2 leading-[21px] text-xs inline-block w-fit rounded-sm">
          {label}
        </lable>
        <div className="text-titleBlack text-base font-semibold leading-[24px] line-clamp-2">
          {title}
        </div>
        <p className="text-xs leading-[18px] text-descriptionBlack line-clamp-3 ">
          {description}
        </p>
        {marketing && (
          <Button
            small
            outline
            text={
              <span className="flex items-center gap-2 justify-center">
                Explore solar panels <ButtonArrow />
              </span>
            }
            onClick={onClick}
            buttonStyle="primary"
            buttonDesign="w-fit"
          />
        )}
      </div>
    </ElementType>
  );
}
Card.Loading = function CardLoading({ horizontal = false, marketing }) {
  return (
    <div
      className={`flex  animate-pulse  gap-4 ${
        horizontal ? 'flex-row items-start' : 'flex-col'
      } ${!marketing && 'cursor-pointer'}`}
    >
      <div
        className={`bg-[#EFEFEF]  rounded-[4px] overflow-hidden relative ${
          horizontal
            ? 'max-w-[170px] w-[30%] flex-shrink-0 pt-[30.32%]'
            : 'w-full pt-[77.703%]'
        }`}
      />
      <div className="flex flex-col gap-2 w-full">
        <div className="text-titleBlack h-5 rounded-full text-base font-semibold leading-[24px] bg-[#EFEFEF]" />
        <p className="h-3 bg-[#EFEFEF] rounded-full" />
        <p className="h-3 bg-[#EFEFEF] rounded-full" />
        {horizontal && !marketing && (
          <p className="h-3 w-4/5 bg-[#EFEFEF] rounded-full" />
        )}
        {marketing && (
          <div className="h-[42px] w-[138px] rounded bg-[#EFEFEF]" />
        )}
      </div>
    </div>
  );
};
export default Card;
