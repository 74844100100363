import React, { useState } from 'react';
import { FaqArrow } from '../../assets/svg';

function Accordion({ title, content }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="">
      <div className="bg-divider-background w-full h-[1px]" />
      <div
        className="flex justify-between items-center bg-gray-200 py-4 cursor-pointer"
        onClick={toggleAccordion}
      >
        <div className="text-base ">{title}</div>
        <span
          className={`transform ${
            isOpen ? 'rotate-180' : 'rotate-0'
          } transition-transform duration-300`}
        >
          <FaqArrow />
        </span>
      </div>
      {isOpen && <div className="bg-white text-sm py-4">{content}</div>}
      <div className="bg-divider-background w-full h-[1px]" />
    </div>
  );
}

export default Accordion;
