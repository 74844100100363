import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Logo, Menu } from '../../../assets/svg';
import { ROUTES } from '../../../common/constants';
import Button from '../../../components/common/Button';

export default function AppHeader() {
  const [openMenu, setOpenMenu] = useState(false);
  const toggleState = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <header className="py-4 border-b border-gray sticky top-0 bg-white z-10">
      <div className="container m-auto px-4">
        <div className="flex items-center justify-between">
          <div className="flex gap-2 sm:gap-4 items-center">
            <div className="lg:hidden cursor-pointer" onClick={toggleState}>
              <Menu />
            </div>
            <Link to={ROUTES?.MAIN}>
              <Logo />
            </Link>
          </div>
          <nav className="flex items-center flex-row bg-white">
            {openMenu && (
              <span
                className="fixed left-0 top-0 bg-black opacity-40 w-full h-full"
                onClick={toggleState}
              />
            )}

            <div
              className={` duration-200 ease-in fixed bg-white max-w-[280px] w-full lg:max-w-max lg:w-auto lg:relative   h-full top-0 flex items-center flex-col lg:flex-row lg:left-0 ${
                openMenu ? 'left-0' : 'left-[-280px]'
              }`}
            >
              <div className="flex items-center gap-3 p-4 border-b border-gray lg:hidden w-full">
                <Logo />
                <div className="text-xs text-primary px-2 py-[2px] border rounded-sm bg-primarylight">
                  For Partners
                </div>
              </div>
              <ul className="flex text-sm leading-5 w-full flex-col lg:flex-row">
                <li className="py-3 px-4 lg:py-0 lg:px-2 xl:px-5">
                  <a
                    className="hover:text-primary duration-200 ease-in"
                    to={ROUTES?.MAIN}
                  >
                    Save
                  </a>
                </li>

                <li className="py-3 px-4 lg:py-0 lg:px-2 xl:px-5">
                  <a
                    className="hover:text-primary duration-200 ease-in"
                    to={ROUTES?.MAIN}
                  >
                    Borrow
                  </a>
                </li>
                <li className="py-3 px-4 lg:py-0 lg:px-2 xl:px-5">
                  <Link
                    className="hover:text-primary duration-200 ease-in"
                    to={ROUTES?.MAIN}
                  >
                    Share
                  </Link>
                </li>
                <li className="py-3 px-4 lg:py-0 lg:px-2 xl:px-5">
                  <Link
                    className="text-primary font-semibold"
                    to={ROUTES?.MAIN}
                  >
                    Green Hub
                  </Link>
                </li>
                <li className="py-3 px-4 lg:py-0 lg:px-2 xl:px-5">
                  <Link
                    className="hover:text-primary duration-200 ease-in"
                    href={ROUTES?.MAIN}
                  >
                    Company
                  </Link>
                </li>
                <li className="py-3 px-4 lg:px-3 lg:py-0 xl:px-5">
                  <Link
                    className="hover:text-primary duration-200 ease-in"
                    to={ROUTES?.MAIN}
                  >
                    Support
                  </Link>
                </li>
              </ul>
              <div className="flex-shrink-0 px-2 lg:px-4 xl:px-8 py-3 border-spacing-0 lg:border-x border-gray mr-2 hidden lg:inline-block lg:mr-4 xl:mr-8">
                <div className="text-sm text-primary px-2 border rounded-sm bg-primarylight">
                  For Partners
                </div>
              </div>
            </div>

            <Button text="Download App" buttonStyle="primary" buttonDesign="" />
          </nav>
        </div>
      </div>
    </header>
  );
}
