import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { ROUTES } from '../../../common/constants';

function TrendingSearchesTags({ loading }) {
  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };
  const [keyWordList] = useState([
    {
      name: 'Vehicle Emissions',
      question:
        'How do vehicle emissions contribute to air pollution in the UK?',
    },
    {
      name: 'Renewable Energy',
      question: 'How can I switch to renewable energy sources in my home?',
    },
    {
      name: 'Sustainable Living',
      question:
        'What are the first steps to transition to a sustainable lifestyle?',
    },
    {
      name: 'Climate Change',
      question: 'What is the UK government doing to combat climate change?',
    },
    {
      name: 'Energy Efficiency',
      question: 'How can I make my home more energy-efficient?',
    },
    {
      name: 'Greenhouse Gases',
      question:
        'What are the main sources of greenhouse gas emissions in the UK?',
    },
    {
      name: 'Recycling',
      question: 'What are the environmental benefits of recycling plastics?',
    },
    {
      name: 'Solar Panels',
      question: 'How cost-effective are solar panels for UK homes?',
    },
    {
      name: 'Electric Vehicle',
      question:
        'How do electric vehicles compare to petrol cars in terms of lifetime emissions?',
    },
    {
      name: 'EV Charger',
      question: 'What are the different types of EV chargers available?',
    },
    {
      name: 'Insulation',
      question: 'What are the best insulation options for UK homes?',
    },
    {
      name: 'Boiler',
      question: 'How can I choose an energy-efficient boiler?',
    },
    {
      name: 'EPC',
      question:
        'What is an Energy Performance Certificate and why do I need one?',
    },
    {
      name: 'Carbon Footprint',
      question: 'How can I calculate and reduce my carbon footprint?',
    },
  ]);

  return (
    <div className="mb-4 relative trending-searches-tags-slider">
      {loading ? (
        'Loading...'
      ) : (
        <Slider {...settings}>
          {keyWordList?.map(({ name, question }) => {
            // const keyword = d?.Url?.split('/')?.[3];
            if (name) {
              return (
                <>
                  {/* <a
                    href={d?.Url}
                    className="inline-block  rounded-full py-[6px] px-4 border border-gray hover:bg-primary hover:text-white hover:border-primary duration-300 ease-in"
                  >
                    {d?.category}
                  </a> */}
                  <Link
                    to={`${ROUTES?.SEARCH_AI}/${question}`}
                    className="inline-block  rounded-full py-[6px] px-4 border border-gray hover:bg-primary hover:text-white hover:border-primary duration-300 ease-in"
                  >
                    {name}
                  </Link>
                </>
              );
            }
            return null;
          })}
        </Slider>
      )}
    </div>
  );
}
export default TrendingSearchesTags;
