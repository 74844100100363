import { doc, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../common/api';
import {
  NO_REFERENCES_FOUND,
  getUniqueValueFromArray,
} from '../common/constants';
import { loop } from '../common/utils';
import BlogFilterTag from '../components/modulecomponents/BlogFilter';
import Card from '../components/modulecomponents/Card';
import { addData, deleteDocument } from '../firebase/api';
import responseRef from '../firebase/collection';
import { db } from '../firebase/config';

let preservedId = '';
export default function SearchAi() {
  const [blogList, setBlogList] = useState([]);
  const [loading, setLoading] = useState('loading');
  const [sessionId, setSessionId] = useState();
  const { question } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = ['Blog', 'Marketplace'];

  const generateRandomNumber = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);

  const fetchRealTimeData = async (table, id, condition) => {
    try {
      const searchQuery = query(table, condition);
      onSnapshot(searchQuery, (snapshot) => {
        // setBlogList([]);
        snapshot.docChanges().forEach(async (change) => {
          if (change.type === 'modified') {
            const data = change.doc.data()?.search_results;
            if (data === 'loading') {
              setLoading('loading');
            } else {
              const parsedData = data ? JSON?.parse(data) : [];
              const uniqueList = getUniqueValueFromArray(parsedData, 'Page');
              const dataWithImage = [];
              Promise.all(
                uniqueList?.map(
                  (item) =>
                    new Promise((resolve) => {
                      api
                        ?.post(`/get_meta`, { url: item?.Url })
                        .then((response) => {
                          dataWithImage?.push({
                            ...item,
                            image: response?.data?.data?.image,
                            randomInt: generateRandomNumber(1, 4),
                          });
                          resolve();
                        });
                    }),
                ),
              ).then(() => {
                setLoading(false);
                setBlogList(dataWithImage);
              });
            }
          }
        });
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchBlogs = async (id) => {
    setSessionId(id);
    preservedId = id;

    await fetchRealTimeData(responseRef, id, where('session_id', '==', id));
  };

  const generateDoc = async () => {
    const id = await addData(responseRef, {});
    await setDoc(doc(responseRef, id), { session_id: id });
    if (id) {
      fetchBlogs(id);
    }
  };

  useEffect(() => {
    generateDoc();
    return () => {
      if (preservedId) {
        const docRef = doc(db, 'response', preservedId);
        deleteDocument(docRef);
      }
    };
  }, []);

  // commenting for future use
  // const fetchInitialBlogs = () => {
  //   setBlogList(DEFAULT_BLOG_LIST);
  // };

  useEffect(() => {
    // fetchInitialBlogs();
  }, []);

  const redirectLink = (link) => {
    // eslint-disable-next-line no-undef
    window?.open(link, '_blank');
  };

  const renderBlogs = useMemo(() => {
    if (loading !== 'loading') {
      if (blogList?.length > 0) {
        if (selectedCategory) {
          if (
            blogList?.filter(
              (item) =>
                item?.Category?.toLowerCase() ===
                selectedCategory?.toLowerCase(),
            )?.length > 0
          ) {
            return blogList
              ?.filter(
                (item) =>
                  item?.Category?.toLowerCase() ===
                  selectedCategory?.toLowerCase(),
              )
              .map((item) =>
                item?.Category === 'marketplace' ? (
                  <Card
                    key={item?.Id}
                    horizontal
                    src={item?.image}
                    title={item?.Page}
                    description={item?.['Reference Text']}
                    href={item?.Url}
                    marketing
                    label={item?.Category}
                    onClick={() => redirectLink(item?.Url)}
                    randomInt={item?.randomInt}
                  />
                ) : (
                  <Card
                    key={item?.Id}
                    horizontal
                    src={item?.image}
                    title={item?.Page}
                    description={item?.['Reference Text']}
                    href={item?.Url}
                    label={item?.Category}
                    randomInt={item?.randomInt}
                  />
                ),
              );
          }
          return <p>{`No ${selectedCategory || ''} Data`}</p>;
        }
        return blogList?.map((item) =>
          item?.Category === 'marketplace' ? (
            <Card
              key={item?.Id}
              horizontal
              src={item?.image}
              title={item?.Page}
              description={item?.['Reference Text']}
              href={item?.Url}
              marketing
              label={item?.Category}
              onClick={() => redirectLink(item?.Url)}
              randomInt={item?.randomInt}
            />
          ) : (
            <Card
              key={item?.Id}
              horizontal
              src={item?.image}
              title={item?.Page}
              description={item?.['Reference Text']}
              href={item?.Url}
              label={item?.Category}
              randomInt={item?.randomInt}
            />
          ),
        );
      }
      return (
        <p className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          {NO_REFERENCES_FOUND}
        </p>
      );
    }
  }, [blogList, selectedCategory, loading]);

  return (
    <div className="wrapper">
      <div className="py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row gap-10 items-start">
            <div className="rounded-[10px] flex-1 w-full md:min-h-[calc(100vh-145px)] md:sticky md:top-[113px]">
              <iframe
                title="test"
                id="chatBox"
                className="min-h-[400px] w-full h-[78vh]"
                src={`${process.env.REACT_APP_SERVER_URL}/?embed=true&session_id=${sessionId}&init_question=${question}`}
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              />
            </div>
            <div className="w-full md:max-w-[442px] md:w-[30%]">
              <div className="mb-4">
                {blogList && blogList?.length > 0 && (
                  <BlogFilterTag
                    categories={categories}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={(category) =>
                      setSelectedCategory(category)
                    }
                  />
                )}
              </div>
              <div className="flex flex-col gap-4">
                {loading === 'loading'
                  ? loop(5)?.map((key, idx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Card.Loading key={idx} marketing horizontal />
                    ))
                  : renderBlogs}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
