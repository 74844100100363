import { addDoc, deleteDoc, getDocs, query } from 'firebase/firestore';
import { toast } from 'react-toastify';

export const handleError = (error) => {
  // eslint-disable-next-line no-console
  toast.error(error?.message);
};
export const fetchData = async (ref) => {
  try {
    const data = await getDocs(ref);
    const firebaseData = data?.docs?.map((doc) => ({
      ...doc.data(),
      id: doc?.id,
    }));
    return firebaseData;
  } catch (error) {
    handleError(error);
  }
};

export const fetchDataWithWhere = async (table, id, condition) => {
  try {
    const data = await getDocs(query(table, condition));
    const firebaseData = data?.docs?.map((doc) => ({
      ...doc.data(),
      id: doc?.id,
    }));
    return firebaseData;
  } catch (error) {
    handleError(error);
  }
};

export const addData = async (ref, payload) => {
  try {
    const docRef = await addDoc(ref, payload);
    return docRef?.id;
  } catch (error) {
    handleError(error);
  }
};

export const deleteDocument = async (docRef) => {
  try {
    await deleteDoc(docRef);
  } catch (error) {
    handleError(error);
  }
};
