import React from 'react';
import Button from '../common/Button';

const BlogFilterTag = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}) => {
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="flex gap-2">
      <Button
        small
        text="All"
        buttonStyle="primary"
        outline={selectedCategory !== null}
        className={`filter-button ${selectedCategory === null ? 'active' : ''}`}
        onClick={() => handleCategorySelect(null)}
      />
      {categories?.map((category) => (
        <Button
          small
          text={category}
          outline={selectedCategory !== category}
          buttonStyle="primary"
          type="button"
          key={category}
          className={`filter-button ${
            selectedCategory === category ? 'active' : ''
          }`}
          onClick={() => handleCategorySelect(category)}
        />
      ))}
    </div>
  );
};

export default BlogFilterTag;
