import React from 'react';

function Button(props) {
  const {
    small,
    text,
    buttonType = 'button',
    buttonStyle = 'default',
    buttonDesign,
    disabled,
    outline,
    onClick,
    textButton,
  } = props;

  const getStyle = (buttonT) => {
    switch (buttonT) {
      case 'default':
        // eslint-disable-next-line no-nested-ternary
        return outline
          ? 'bg-transparent text-gray-400 border-[1px] border-gray-400 hover:bg-gray-400 hover:text-white'
          : textButton
          ? 'text-gray-400'
          : 'bg-gray-400';
      case 'primary':
        // eslint-disable-next-line no-nested-ternary
        return outline
          ? 'bg-transparent border-[1px] text-primary border-primary hover:bg-primary [*] hover:text-white'
          : textButton
          ? 'text-primary'
          : 'bg-primary text-white';
      case 'success':
        // eslint-disable-next-line no-nested-ternary
        return outline
          ? 'bg-transparent border-[1px] border-primary-600 text-primary hover:bg-primary hover:text-white'
          : textButton
          ? 'text-primary'
          : 'bg-primary text-white';
      case 'danger':
        // eslint-disable-next-line no-nested-ternary
        return outline
          ? 'bg-transparent border-[1px] border-red-600 text-red-600 hover:bg-red-600 hover:text-white'
          : textButton
          ? 'text-red-600'
          : 'bg-red-600 text-white';
      case 'warning':
        // eslint-disable-next-line no-nested-ternary
        return outline
          ? 'bg-transparent border-[1px] border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white'
          : textButton
          ? 'text-yellow-500'
          : 'bg-yellow-500 text-white';

      default:
        break;
    }
  };
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={buttonType}
      className={`rounded-[4px]  hover:opacity-80 text-center text-xs md:text-sm leading-[18px] duration-300 ease-in
        ${disabled ? 'cursor-not-allowed opacity-50 hover:opacity-50' : ''} ${
        small ? 'py-2 px-3' : 'py-2 px-4 md:py-[14px] md:px-6'
      }
        ${getStyle(buttonStyle)}
        ${buttonDesign}`}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
}
export default Button;
