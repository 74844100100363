import * as Sentry from '@sentry/react';
import axios from 'axios';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-cycle
import { SOMETHING_WENT_WRONG, TOAST_TYPES } from './constants';

const api = axios?.create({
  baseURL: process.env.REACT_APP_metaApiUrl,
});

const populateToast = ({ message: content, type }) => {
  switch (type) {
    case 'info':
      toast?.info(content);
      break;
    case 'success':
      toast?.success(content);
      break;
    case 'warning':
      toast?.warn(content);
      break;
    case 'error':
      toast?.error(content);
      break;
    default:
      break;
  }
};

api.interceptors.request.use(
  async (config) => {
    try {
      // eslint-disable-next-line no-undef
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `12345678`;
      return config;
    } catch (error) {
      return error;
    }
  },
  (error) => Promise.reject(error),
);

api?.interceptors?.response?.use(
  (response) => {
    if (
      response?.config?.method !== 'get' &&
      !response?.config?.url?.includes('credits/needed/credits') &&
      !response?.config?.url?.includes('search') &&
      !response?.config?.url?.includes('file/user-info') &&
      !response?.config?.url?.includes('ftp/download_file') &&
      !response?.config?.url?.includes('get_meta')
    )
      populateToast({
        message: response?.data?.message || 'Operation successful',
        type: response?.status === 'ERROR' ? 'error' : 'success',
      });
    return response;
  },
  (error) => {
    if (error?.response) {
      Sentry.captureException(
        new Error(
          `[Response error]: Message: ${error?.response?.data?.message}`,
        ),
      );
      populateToast({
        message: error?.response?.data?.message || SOMETHING_WENT_WRONG,
        type: TOAST_TYPES?.ERROR,
      });
    }

    // following logic may be changed in the future
    if (error?.response?.data?.statusCode === 401) {
      // eslint-disable-next-line no-undef
      localStorage?.clear();
      // eslint-disable-next-line no-undef
      window.location.href = ROUTES?.LOGIN;
    }
  },
);

export default api;
