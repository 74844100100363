import React from 'react';

export default function HighlightText({ text, searchTerm }) {
  // Split the text into an array of words
  const words = text?.split(' ');

  // Map each word to a span element with the highlight class if it matches the searchTerm
  const highlightedWords = words?.map((word, index) => {
    if (
      word?.toLowerCase()?.includes(searchTerm?.toLowerCase()) &&
      searchTerm
    ) {
      return (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="highlight mr-1 text-primary bg-primarylight"
        >
          {word}
        </span>
      );
    }
    // eslint-disable-next-line react/no-array-index-key
    return (
      // eslint-disable-next-line react/no-array-index-key
      <span className="mr-1" key={index}>
        {word}
      </span>
    );
  });

  return <>{highlightedWords}</>;
}
